<template>
   <div class="common-layout">
    <el-container>
      <el-aside :width="isCollapse?'70px': '210px'" style="background:#fff;box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;transition-duration: 0.3s, 0.3s, 0.3s;">
        <div class="menu-title">
          <span v-if='!isCollapse'>我的主题</span> 
          <i class="iconfont" :class="{'ec-icon-s-fold':!isCollapse,'ec-icon-s-unfold':isCollapse}" @click="handleCollapse"></i>
        </div>
        <el-scrollbar :style="{'height':(windowHeight-135)+'px'}">
        <el-menu
          :default-active="selthemeIds"
          :collapse="isCollapse"
        >
        <!--使用v-if语句进行判断有无children菜单，确定一级菜单是否需要下拉功能-->
          <template v-for="(item,index) in ecoThemeList">
          <el-submenu
              text-color="#fff"
              :index="item.ids"
              :key="item.ids"
              v-if="item.children?.length>0">
            <!--一级菜单在submenu中为下拉状态-->
            <template #title>
             <i  class="iconfont i_icon" :class="item.icon"></i>
              <span>{{item.name}}</span>
            </template>
            <!--二级菜单再次进行v-if判断是否显示二级菜单-->
            <template v-for="item2 in item.children" :key="item2.ids">
              <el-menu-item :index="item2.ids" @click="selThemeFun">
                <span>{{item2.name}}</span>
              </el-menu-item>
            </template>
          </el-submenu>

          <!--一级菜单使用v-else在不在submenu中为非下拉状态-->
          <el-menu-item :index="item.ids" :key="item.ids" v-else @click="selThemeFun(item.ids,index,item.themeType)">
            <i  class="iconfont i_icon" :class="item.icon"></i>
            <template #title>
              <span>{{item.name}}</span>
            </template>
          </el-menu-item>
          </template>
        </el-menu>
        </el-scrollbar>
        <p  v-if='ecoThemeList.length<1' style="text-align:center;font-size:16px;color:#99a2aa;">还没有主题快去创建吧~</p>
      </el-aside>
      <el-main style="position:relative;padding:0px;">
        <ul ref='areaEcoBox' class="area-eco clearfix" v-infinite-scroll="load" style="overflow: auto;padding: 10px;" :style="{'height': (windowHeight-81)+'px'}">
          <li v-for="item in areaEcoList" :key="item.id">
            <transition>
              <div>
                <span class="span-txt">{{item.title}}</span>
                <el-tooltip v-if='item.desc' effect="light" :content="item.desc" placement="top-start">
                  <i class="iconfont ec-icon-warning i-tooltip"></i>
                </el-tooltip>
                <div v-if='item.showMode=="list_sort"' class="eco-content">
                  <el-table v-loading="item.loadingShow" element-loading-text="正在加载中..."
                    :data="item?.dtList" height='100%' stripe size='small' style="width: 100%" :row-class-name="tableRowClassName">
                    <el-table-column type="index" label="排名" width="80" >
                        <template #default="scope">
                          <!-- <span :class="{'sort-index':scope.$index<9}">{{ scope.$index + 1}}</span>  -->
                          <span >{{ scope.row.sortOrder}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="regionName" label="地区"  />
                    <el-table-column prop="itemValue" :label="item?.itemName?item?.itemName+'('+item?.itemUnit+')':'值'">
                      <template #default="scope">
                        {{ scope.row.itemValue?scope.row.itemValue:'-' }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div v-else :id='"chart_"+item.id' v-loading="item.loadingShow" element-loading-text="正在加载中..." class="eco-content">
                </div>
                <span class="eco-date">{{item?.date?'日期：'+item?.date:''}}&nbsp;</span>
                <span class="eco-date" v-if='item?.itemSource && item?.itemSource.length==1'>数据来源：{{item?.itemSource[0]}} </span>
                <el-tooltip v-if='item?.itemSource && item?.itemSource.length>1' effect="dark" :content="item?.itemSource.join('、')" placement="top-start">
                  <span class="eco-date" >数据来源：{{item?.itemSource[0]+'...'}}</span>
                </el-tooltip> 
              </div>
            </transition>
          </li>
          <li v-if='loadNum<pageIndex' style="text-align:center;width:calc(100% - 20px);;box-shadow:none;background: transparent;">正在加载中...</li>
          <li v-if='loadNum>=pageIndex && pageIndex*pageSize>=totalCount' style="margin:20px 10% 10px 10%; width:80%;background-image: linear-gradient(to right,transparent,#002FA7, transparent);height: 1px;box-shadow:none;"></li>
        </ul>
      </el-main>
    </el-container>
    
  </div>

</template>
<script>
import {getEcoThemeListSelf,getAreaEcoListOfTheme,getRegionItemSort,getRegionItemPie,getRegionItemLine} from '@/http/ecoApi'
import {getRegionInfo,addUserLog} from '@/http/basicsApi.js'
import { ref } from '@vue/reactivity';
import * as echarts from 'echarts'
import {parseTimeOfCycle,colors} from '@/utils'
import { computed, nextTick, onBeforeUnmount, watch } from '@vue/runtime-core';
import store from '../../store'
// 引入封装的nprogress.js
import { start, close } from '@/utils/nprogress';
import { useRouter } from 'vue-router'
export default {
  setup() {
    const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
    let router=useRouter();
    //选中地区信息
    let regionCode=computed(()=> store.state.curRegionCode);
    let regionName=ref('');
    let echartsAry=ref([]);//echart对象
   
    //根据地区获取主题列表
    let ecoThemeList=ref([]);//主题列表
    let selthemeIds=ref(router.currentRoute.value.query.ids);//选中的主题ID
    let pageSize=ref(6);//每页显示的区域经济部件数
    let pageIndex=ref(1);//页数
    let totalCount=ref(0);//区域经济部件总数
    let areaEcoBox=ref(null);//区域经济的容器

    const getEcoThemeListFun= async()=>{
      const result= await getEcoThemeListSelf(regionCode.value);
      //console.log(result);
      if(result.code==20000){
        ecoThemeList.value=result.data;
        if(result.data && result.data.length>0){
            if(selthemeIds.value.length<=0){
                selthemeIds.value=result.data[0].ids
            }
            getAreaEcoListOfThemeFun(1);
        }
        
      }
    }
    
    //改变菜单的折叠
    let isCollapse=ref(false);
    const handleCollapse=()=>{
      isCollapse.value=!isCollapse.value;
      //图表自适应
      echartsAry.value.forEach(element => {
        element.resize();
      });
    }


    let areaEcoList=ref([]);//区域经济列表
    let loadNum=ref(0);
    /**
     * 获取区域经济部件
     * 参数type,1:重新加载，2：翻页，滚动加载
     */
    const getAreaEcoListOfThemeFun= async(type)=>{
      start();
      let param={
        themeId:selthemeIds.value,
        regionCode:regionCode.value,
        themeType:2,//个性主题
        pageIndex:pageIndex.value,
        pageSize:pageSize.value,
      }
      const result=await getAreaEcoListOfTheme(param);
      if(result.code==20000){
        loadNum.value++;
        //console.log(result.data);
        totalCount.value=result.data.totalCount;
        if(type==1){//重新加载
          areaEcoList.value=  result.data.dataList;
        }
        else{//翻页，滚动加载
          areaEcoList.value=  areaEcoList.value.concat(result.data.dataList);
        }
        areaEcoList.value.forEach((t,index)=>{
          if(index< (pageIndex.value-1)*pageSize.value)
          {
            return
          }
          t.loadingShow=true;
          let param;
          let rule=null;
          if(t.rule){
            rule=JSON.parse(t.rule);
          } 
          if(t.showMode=="list_sort"){//排名
            //console.log(rule);
            let item=JSON.parse(t.items)[0];
            param={
              regionCode:regionCode.value,
              regionRange:rule?.sort?rule.sort:'',
              itemId:item.itemId,
              ObjId:item.objId
            }
            getRegionItemSort(param).then(res=>{
              //console.log(res);
              if(res.code==20000){
                t.dtList=res.data.dataList?res.data.dataList:[];
                t.itemName=res.data.itemName;
                t.itemUnit=res.data.itemUnit;
                t.date=res.data.date && res.data.itemCycle?parseTimeOfCycle(res.data.date,res.data.itemCycle):'';
                t.itemSource=res.data.itemSource?.split('、');
                t.loadingShow=false;
               
               
              }
            });
          }
          else if(t.showMode=="chart_line" || t.showMode=="chart_bar"){//趋势，折线图
            param={
              regionCode:regionCode.value,
              itemId:t.items
            }
            getRegionItemLine(param).then(res=>{
              //console.log(t.title);
              //console.log(res);

              if(res.code==20000){
                let itemCycle=res.data.itemCycle;
                let seriesAry=[];
                let itemSourceAry=[];
                let legendData=[];
                let yAxisAry=[];
                if(rule?.yAxisNum>1){
                  rule.item.forEach((t,i)=>{
                    yAxisAry.push({
                      type: 'value',
                      position:i==0?'left':'right',
                      alignTicks:true,
                      offset:i>1?(i-1)*60:0,
                      axisLine: {
                          show: true,
                          lineStyle: {
                              color: 'gray',
                          }
                      },
                      axisLabel:{
                          margin : 3
                      } 
                    })
                  })
                }
                else {
                  yAxisAry.push({
                    type: 'value'
                  })
                }
                //console.log(rule);
                //console.log(yAxisAry);
                res.data.item.forEach(m=>{
                  itemSourceAry.push(m.itemSource);
                  legendData.push(m.itemName+'('+m.itemUnit+')');
                  //如果是多轴，获取指标项所在多轴中的索引，即第几个轴
                  let index=0;
                  if(rule?.yAxisNum>1){
                    rule.item.forEach((el,ii)=>{
                      //console.log('el',el);
                      //console.log('id', m.id);
                     
                      let ary=el.split(',');
                      //console.log('ary',ary);
                      //console.log(ary.indexOf(m.id.toString()));
                      if(ary.indexOf(m.id.toString())>-1){
                        index=ii;
                        return;
                      }
                    })
                  }
                  seriesAry.push({
                    name:m.itemName+'('+m.itemUnit+')',
                    type:t.showMode.split('_')[1],
                    smooth: true,
                    data:m.itemValue,
                    yAxisIndex:index,
                  })
                 
                })
               
                //指标来源，去重
                itemSourceAry= [...new Set(itemSourceAry.join('、').split('、'))]
                t.itemSource=itemSourceAry;
                //日期
                if(res.data.date && res.data.date.length>0 && res.data.itemCycle){
                  t.date='开始于'+parseTimeOfCycle(res.data.date[0],res.data.itemCycle)
                }
                t.loadingShow=false;
                nextTick(()=>{
                  let myChart =echarts.init(document.getElementById("chart_"+t.id));
                  let option = {
                    color:colors,
                    tooltip: {
                      trigger: 'axis',
                    },
                    legend: {
                      type: 'scroll',
                      pageIconColor:'#79bbff',
                      // pageIconInactiveColor:'#aaa',
                      pageTextStyle:{
                        color:'#9CDCFE',
                      },
                      // top: '5',
                      left: 'center',
                      data:legendData
                      
                    },
                    dataZoom: [
                      {
                        type: 'inside',
                        start: 60,
                        end: 100
                      },
                      {
                        show: true,
                        height: 16,
                        bottom:30,
                        start: 60,
                        end: 100
                      }
                    ],
                    grid: {
                      top: 60,
                      bottom: 80,
                      //left:10,
                      //right:rule?.yAxisNum>1?'15%':20,
                      containLabel:true
                    },
                    xAxis: [
                      {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                        },
                        axisLabel: {
                          formatter: function (params) {
                            return parseTimeOfCycle(params,itemCycle)
                          }
                        },
                        axisPointer: {
                          label: {
                            formatter: function (params) {
                              return parseTimeOfCycle(params.value,itemCycle)
                            }
                          }
                        },
                        data: res.data.date
                      },
                    ],
                    yAxis: yAxisAry,
                    series: seriesAry,
                  };
                  myChart.setOption(option);
                  echartsAry.value.push(myChart);
                });
              }
            })
          }
          else if(t.showMode=="chart_pie"){//占比，饼图
            param={
              regionCode:regionCode.value,
              itemId:t.items
            }
            getRegionItemPie(param).then(res=>{
              //console.log(res);
              if(res.code==20000){
                t.date=res.data?.date && res.data.itemCycle?parseTimeOfCycle(res.data?.date,res.data.itemCycle):'';
                let itemSourceAry=[];
                itemSourceAry=res.data?.dataList.map(m=>m.itemSource);
                //console.log(itemSourceAry)
                if(itemSourceAry && itemSourceAry.length>0){
                  itemSourceAry=[...new Set(itemSourceAry.join('、').split('、'))];
                }
                t.itemSource=itemSourceAry;
                t.loadingShow=false;
               
                nextTick(()=>{
                  let myChart =echarts.init(document.getElementById("chart_"+t.id));
                  let option = {
                    color:colors,
                    graphic: 
                    {
                      type: 'text',
                      z: 100,
                      left: 'center',
                      top: 'middle',
                      silent: true,
                      invisible: !res.data?.dataList ||res.data?.dataList.length== 0?false:true, //是否可见，这里的意思是当没有数据时可见
                      style: {
                        fill: '#fff',
                        //fontWeight: 'bold',
                        text: '暂无数据',
                        fontSize: '16px'
                      }
                    },
                    tooltip: {
                      trigger: 'item',
                      formatter: '{a} <br/>{b} <br/> {c} ({d}%)'
                    },
                    legend: {
                      type: 'scroll',
                      pageIconColor:'#79bbff',
                      // pageIconInactiveColor:'#aaa',
                      pageTextStyle:{
                        color:'#9CDCFE',
                      },
                      // top: '5',
                      left: 'center',
                      data:res.data?.dataList.map(t=>t.itemName+'('+t.itemUnit+')'),
                    },
                    series: [
                      {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        emphasis: {
                          itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                          },
                          label: {
                            show: true,
                            // fontSize: '40',
                            fontWeight: 'bold'
                          }
                        },
                        data: res.data?.dataList.map(t=>{
                          return {
                            value:t.itemValue,
                            name:t.itemName+'('+t.itemUnit+')'
                          }
                        }),
                      }
                    ]
                  };
                  myChart.setOption(option);
                  echartsAry.value.push(myChart);
                });
              }
            });
          }
        })
      }
      close();
    }
    //选择不同的主题
    const selThemeFun=(ids)=>{
        if(selthemeIds.value!=ids){
            echartsAry.value=[];
            pageIndex.value=1;
            selthemeIds.value=ids;
            loadNum.value=0;
            areaEcoBox.value.scrollTop=0;
            areaEcoList.value=[];
            getAreaEcoListOfThemeFun(1);
            //记录用户操作日志
            let param={themeIds:ids};
            addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'选主题',JSON.stringify(param));
        }
    }
    //滚动加载
    const load=()=>{
        if(pageIndex.value * pageSize.value > totalCount.value || loadNum.value<pageIndex.value) return
        pageIndex.value++;
        getAreaEcoListOfThemeFun(2);
    }
    // 滚动加载
    // const lazyLoading= ()=> {
    //     const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;// 滚动条滚动时，距离顶部的距离
    //     //const windowHeight = document.documentElement.clientHeight;// 可视区的高度
    //     const scrollHeight = document.documentElement.scrollHeight;// 滚动条的总高度
    //     // 滚动条到底部
    //     if (scrollTop + windowHeight.value >= scrollHeight-400 ) {
    //         if (pageIndex.value * pageSize.value > totalCount.value || loadNum.value<pageIndex.value) return
    //         pageIndex.value++;
    //         getAreaEcoListOfThemeFun(2);
    //     }
    // }
    //
    const tableRowClassName = (obj) => {
      //console.log(obj.row);
      if (obj.row.regionCode === regionCode.value) {
        return 'highlight'
      } 
      return ''
    }
  
    
    watch(
      ()=>regionCode.value,
      (newVal,oldVal)=>{
        //console.log('监听全局变量regionCode',newVal,oldVal);

        if(!oldVal && newVal){
          //执行
          //console.log(newVal);
          getRegionInfo(newVal).then((res)=>{
          if(res.code==20000){
              regionName.value=res.data.name
            }
          });
          getEcoThemeListFun();
          //记录用户操作日志
          addUserLog(router.currentRoute.value.name,router.currentRoute.value.fullPath,'加载','{"themeIds":"'+router.currentRoute.value.query.ids+'"}');
        }
      },
      {
        immediate:true,// 这个属性是重点啦
        //deep:true // 深度监听的参数
      }
    )
    
    //自适应
    let listener=()=> {
      echartsAry.value.forEach(element => {
        element.resize();
      });
    }
    window.addEventListener('resize', listener)//监听resize事件
    //window.addEventListener('scroll',lazyLoading);
    onBeforeUnmount(()=>{//销毁之前
        //window.removeEventListener('scroll',lazyLoading)
        window.removeEventListener('resize',listener);
        echartsAry.value.forEach(element => {
          if (element) {
            element.clear(); //清空图表
            element.dispose(); //释放图表组件
            element = null;
          }
        }); 
    })
    return{
      windowHeight,
      regionName,
      ecoThemeList,
      isCollapse,
      handleCollapse,
      areaEcoBox,
      selthemeIds,
      selThemeFun,
      pageIndex,
      pageSize,
      totalCount,
      areaEcoList,
      loadNum,
      tableRowClassName,
      load
    }
  },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.span-txt{
    line-height: 30px;
    font-weight: bold;
    padding-left: 10px;
    color: $specialtext-color;
}
.fav-item {
    position: relative;
    padding-left: 20px;
    transition: background-color .3s ease;
    white-space: nowrap;
    overflow: hidden;
    line-height: 44px;
    cursor: pointer;
    a{
        font-size: 14px;
        width: 100%;
        color: #fff;
        justify-content:left;
    }
}
.fav-item.cur,.fav-item.cur:hover {
  background: rgba(63, 148, 253, 0.3);   
  border-left: 3px solid rgba(63,148,253,1);
}
.fav-item:hover {
  background-color: rgba(63, 148, 253, 0.05);
  a{
    color: #fff;
  }
}
.area-eco li{
  float: left;
  width: calc(50% - 20px);
  background: #fff;
  border-radius: 4px;
  margin: 10px;
  position: relative;
  // border: 1px solid $bordercolor;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 3px 0px;
}

.eco-date{
  color: #aaa;
  font-size: 12px;
  display: inline-block;
  line-height: 30px;
  height: 30px;
  padding-left: 10px;
  &:focus{
    outline: none;
  }
}
.eco-content{
  height: 400px;
  width: 100%;
  padding: 10px 10px 0 10px;
}
.sort-index{
  display: inline-block;
  background: #fc5531;
  width: 20px;
  text-align: center;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
}
// ::v-deep .cus-divider  .el-divider__text {
//     background-color: #1E1E1E;
//     color: #3F94FD;
// }

</style>